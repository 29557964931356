import React, {Component} from 'react';
// import { Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
// import HomeAltAlt from "./HomeAltAlt/HomeAltAlt";
import logo from './assets/images/cole-logo.png';
import beef1 from './assets/images/cole-farm-counter.jpg';
import beef from './assets/images/cole-farm-store.jpg';
import farm from './assets/images/coleFarm.jpg';
import farm2 from './assets/images/cows.jpg';

class App extends Component {
  render() {
    // let routes = (
    //   <Switch>
    //     <Route exact={true} path="/" component={HomeAltAlt} />
    //   </Switch>
    // );

    return (
      <div className={classes.cfWrap}>
        <main className={classes.Content}>
          {/* {routes} */}
          <div className={classes.HomeAlt}>
            <div className={classes.Header} uk-scrollspy="cls: uk-animation-fade; target: a, img; delay: 150;">
              <div className={classes.medDef}>
                <div className={[classes.topItem, classes.tiPhone].join(' ')}>
                  <a className={classes.contactLink} href="tel:+17702657039">(770) 265-7039</a>
                </div>
                <div className={[classes.topItem, classes.tiEmail].join(' ')}><a className={classes.contactLink} href="mailto:colefamilyfarms85@gmail.com">colefamilyfarms85@gmail.com</a></div>
                <div className={classes.hdLogo}><img src={logo} className={[classes.imgResponsive, classes.logoImg].join(' ')} alt="Cole Family Farms logo, Carroll County, GA" /></div>
              </div>
            </div>

            <div className={[classes.inside, classes.genInside].join(' ')}>
              <div className={classes.HomeWrap}>
                <div className={[classes.bgBlock, classes.other].join(' ')} uk-parallax="bgy: 150" uk-scrollspy="cls: uk-animation-fade; target: img, h1, h2, h3, a, button; delay: 150;">
                  <div className={classes.textBox}>
                    <h1>Cole Family Farms</h1>
                    <h2>Cattle Farm &amp; Beef Store <br/>in Carrollton, GA</h2>
                    <h3>Locally raised, quality beef</h3>

                    <div className={classes.clearfixBtn} />
                    <button className={[classes.cfLink, "uk-button uk-button-default"].join(' ')} type="button" uk-toggle="target: #modal-cuts">Cuts of Beef</button>
                    <a 
                      href="https://www.google.com/maps/dir//771+Mandeville+Rd,+Carrollton,+GA+30117/@33.664901,-85.1246857,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x888b27c8cfd4ed61:0x381cf569fbb8eec5!2m2!1d-85.122497!2d33.664901"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.cfLinkAlt}>
                      Get Directions</a>
                  </div>
                </div>
            
                <div className={classes.cfBody} uk-scrollspy="cls: uk-animation-fade; target: .uks-item; delay: 150;">
                  <div className={classes.cfIntro}>
                    <div className={classes.medDef}>
                      <div className={[classes.cfiHd, "uks-item"].join(' ')}>
                        <p>Visit Our Farm Store</p>
                        <h3>Family Owned &amp; Operated<br/>Beef Quality Assurance Certified<br/>USDA Inspected</h3>
                      </div>
                      
                      <div className={[classes.cfContact1, "uks-item"].join(' ')}>
                        <div className={classes.cfcItem}>
                          <p><span>Location:</span><br/>771 Mandeville Rd<br/>Carrollton, GA 30117</p>
                          <a 
                            href="https://www.google.com/maps/dir//771+Mandeville+Rd,+Carrollton,+GA+30117/@33.664901,-85.1246857,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x888b27c8cfd4ed61:0x381cf569fbb8eec5!2m2!1d-85.122497!2d33.664901"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.textLink}>
                            Get Directions</a>
                          </div>

                        <div className={classes.cfcItem}>
                          <p><span>Store Hours:</span><br/>Thursday &amp; Friday: 10am-6pm<br/>Saturday: 10am-1pm</p>
                        </div>
                      </div>

                      <div className={[classes.cfContact2, "uks-item"].join(' ')}>
                        <div className={classes.cfcItem}>
                          <p><span>Phone:</span><br/><a href="tel:+17702657039">(770) 265-7039</a><br/><a href="tel:+17705966896">(770) 596-6896</a></p>
                        </div>

                        <div className={classes.cfcItem}>
                          <p><span className={classes.stip}>Email:</span><br className={classes.stip}/><a href="mailto:colefamilyfarms85@gmail.com">colefamilyfarms85@gmail.com</a></p>
                        </div>

                        <div className={classes.cfcItem}>
                          <p className={classes.stip}><span>Follow Us:</span>
                          <a href="https://www.facebook.com/colefamilyfarms1985" target="_blank" rel="noreferrer" className={classes.social} title="facebook" uk-icon="facebook">
                            <span className={classes.visuallyHidden}>Facebook</span>
                          </a>
                          <a href="https://www.instagram.com/colefamilyfarms85/" target="_blank" rel="noreferrer" className={classes.social} title="instagram" uk-icon="instagram">
                            <span className={classes.visuallyHidden}>Instagram</span>
                          </a></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.cfLinkSec}>
                    <div className={classes.insideAlt}>
                      <div className={classes.priceLinkTop}>
                        <button className={[classes.priceBtn, "uk-button uk-button-default uks-item"].join(' ')} type="button" uk-toggle="target: #modal-cuts">View Cuts of Beef</button>
                      </div>
                      <div className={[classes.cfItemImage, classes.img0, "uks-item"].join(' ')}>
                        <img src={beef1} className={classes.imgResponsive} alt="Cole Family Farms Meat Store Counter, Carrollton, Georgia" />
                      </div>

                      <div className={[classes.cfItemImage, classes.img2, "uks-item"].join(' ')}>
                        <img src={beef} className={classes.imgResponsive} alt="Cole Family Farms Meat Store Freezer" />
                      </div>
                    </div>

                    <div className={classes.medDef}>
                      <div className={[classes.cfItem, "uks-item"].join(' ')}>
                        <p><b>Cole Family Farms</b> is <b>Beef Quality Assurance Certified,</b> to insure the <b>best handling practices</b> of our cattle. 
                          Our calves are <b>born and raised</b> in the pastures on our farm, and fed with a <b>custom blend of all natural ingredients. </b> 
                          <span>We never use any growth promoters.</span></p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.cfHistory}>
                    <div className={classes.inside}>
                      <div className={classes.hlf1}>
                        <div className={[classes.textBox, "uks-item"].join(' ')}>
                          <h3>Our History</h3>
                          <p>In the early 1950’s, Tony’s mom and dad purchased the original portion of the Cole farm and began raising cattle.
                          It didn’t take them long to determine they had to continue their public jobs to support the farm. So in the early 1960’s, 
                          they built the first of three poultry houses. The litter from the poultry houses helped supply fertilizer for the pastures 
                          and hay fields. The entire family pitched in to take care of the chickens and cows.</p>
                        </div>

                        <img src={farm2} className={[classes.imgResponsive, classes.reg, "uks-item"].join(' ')} alt="Cows grazing at Carroll County Farm" />
                        <img src={farm} className={[classes.imgResponsive, classes.mobileOnly, "uks-item"].join(' ')} alt="Sunset at Cole Family Farms in Carrollton, Georgia" />
                      </div>

                      <div className={classes.hlf2}>
                        <img src={farm} className={[classes.imgResponsive, classes.fwOnly, "uks-item"].join(' ')} alt="Sunset over the pasture at Cole Family Farms" />
                        <div className={[classes.textBox, "uks-item"].join(' ')}>
                          <p>Tony and Debbie were married in the 1980’s. Debbie came from a family of vegetable farmers, and quickly learned how to help take care of the cows and chickens. 
                          Shortly after getting married, they acquired a farm joining the original farm, and began to build their own herd of cattle. 
                          In the 1990's, they also built poultry houses, which they operated until 2017.</p>
                          <p>In 2017, Tony and Debbie began to explore the idea of supplying friends and neighbors with high quality cuts of beef. 
                          With the help of their sons, David and Tyler, they decided to convert one of the poultry houses into feeding pens for cattle, and 
                          another into a retail meat store.
                          </p>
                          
                          <p>Tony, Debbie, David, and Tyler look after the day-to-day operation of the farm and store. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.cfFooter} uk-scrollspy="cls: uk-animation-fade; target: img, p; delay: 150;">
                <div className={classes.inside}>
                  <img src={logo} className={[classes.imgResponsive, classes.ftLogo].join(' ')} alt="" />
                  <div className={classes.copyHs}><p className={classes.copy}> Cole Family Farms © {(new Date().getFullYear())}</p></div>
                </div>
              </div>
            </div>
          </div>

          <div id="modal-cuts" className="uk-flex-top" data-uk-modal>
            <div className={[classes.ukmCuts, "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>
              <button className="uk-modal-close-default" type="button" data-uk-close></button>
              <div className={classes.textBox}>
                <h4>USDA Individual Cuts Available<br/><span>Call ahead to check availability</span></h4>
                <ul className={classes.steakList}>
                  <li>Rib Eye * ^</li>
                  <li>Filet * `</li>
                  <li>Sirloin * ^</li>
                  <li>New York Strip * ^</li>
                  <li>Short Ribs</li>
                  <li>Chuck Roast</li>
                  <li>Rump Roast</li>
                  <li>Sirloin Tip Roast</li>
                  <li>Half Brisket</li>
                  <li>Hamburger</li>
                  <li>Cubed Steak</li>
                  <li>Stew Meat</li>
                  <li>Fajita Meat</li>
                </ul>
                <p className={classes.legend}>
                  ^ Steaks one inch thick<br/>
                  ` Steaks one and one half inch thick<br/>
                  * Two steaks per pack 
                </p>
              </div>
            </div>
          </div>
        </main>

        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#2b2a28', width: '20px', padding: '0px 5px 2px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}

export default App;
